// src/components/TermsAndConditions.js
import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1 className="terms-title">Terms and Conditions</h1>
            <p className="terms-text">
                By registering on the website, you agree to abide by our policies and terms. Each member is required to provide accurate and truthful information. Upon registration, you will be deemed an active member; however, to establish a team and qualify for commissions, the purchase of a product is mandatory.
            </p>
            <h2 className="terms-section-title">1. Membership and Registration</h2>
            <p className="terms-text">
                By registering on the website, you agree to abide by our policies and terms. Each member is required to provide accurate and truthful information. Upon registration, you will be deemed an active member; however, to establish a team and qualify for commissions, the purchase of a product is mandatory.
            </p>
            <h2 className="terms-section-title">2. Mandatory Product Purchase</h2>
            <p className="terms-text">
                The purchase of a product is imperative for team formation and commission eligibility. Membership or registration alone does not confer any commission. Commissions will only be awarded when the individual you have referred purchases a product.
            </p>
            <h2 className="terms-section-title">3. Referral Link and Commissions</h2>
            <p className="terms-text">
                You will be provided with a referral link that can be shared with others. Individuals who register on the website using your link will become part of your team. Commissions will be granted only if the referred individual purchases a product; mere registration does not qualify for commission.
            </p>
            <h2 className="terms-section-title">4. Commission Calculation</h2>
            <p className="terms-text">
                The calculation of commissions is based on product sales made by you and those within your team. Commissions will only be awarded for individuals who connect through your referral link and subsequently make a purchase.
            </p>
            <h2 className="terms-section-title">5. Commission Payment</h2>
            <p className="terms-text">
                Commission payments will be made within the timeframe specified on the website. The bank details provided by you will be used for payment processing; therefore, it is crucial that the information supplied is accurate.
            </p>
            <h2 className="terms-section-title">6. Fraud and Misconduct</h2>
            <p className="terms-text">
                Any instance of fraud, misinformation, or misconduct may result in the revocation of your membership and the withholding of your commissions. Every member is obligated to comply with our policies and regulations.
            </p>
            <h2 className="terms-section-title">7. Refund and Return Policy</h2>
            <p className="terms-text">
                The refund and return policy for products will follow the stipulations outlined on the website. Should a product be returned, any commissions accrued from that transaction will be rescinded.
            </p>
            <h2 className="terms-section-title">8. Policy Amendments</h2>
            <p className="terms-text">
                The company reserves the right to amend these terms and conditions at any time. You will be notified of any amendments via the website, and compliance with the revised terms will be required.
            </p>
            <h2 className="terms-section-title">9. Legal Liabilities</h2>
            <p className="terms-text">
                All members are bound to adhere to all applicable laws and regulations. In case of any disputes, the company’s decision shall be final and binding.
            </p>
            <p className="terms-text">
                Adherence to these terms and conditions is mandatory for every member. Breaches may result in actions being taken by the company.
            </p>
        </div>
    );
};

export default TermsAndConditions;
