import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import './userProfile.css'; // Import your CSS file

function Userprofile() {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null); // State to hold user data

  // Function to retrieve user data from localStorage
  const getUserDataFromLocalStorage = () => {
    const userProfile = JSON.parse(localStorage.getItem('userProfile')); // Get user data from localStorage
    if (userProfile) {
      setUserData(userProfile); // Set the user data state
    } else {
      console.error('No user data found in localStorage.');
    }
  };

  useEffect(() => {
    getUserDataFromLocalStorage(); // Fetch user profile data from localStorage when the component mounts
  }, []);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // If no user data is available, show a loading indicator or a message
  if (!userData) {
    return <div>Loading...</div>; // You can replace this with a spinner or a better loading indicator
  }

  return (
    <Container>
      <Row className="justify-content-center align-items-center">
        <Col md="auto" className="mt-5">
          <div onClick={handleOpenModal} className='profile-img-logo'>
            <img
              src="./Leonardo_Phoenix_give_me_a_user_profile_image_of_a_boy_with_ca_1.jpg" // Placeholder image
              alt="Profile"
              className="profile-logo"
              width={50}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>User Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card className="profile-card">
                <Card.Body className="p-4">
                  <div className="d-flex profile-info">
                    <div className="flex-shrink-0">
                      <Card.Img
                        className="profile-image"
                        src="./Leonardo_Phoenix_give_me_a_user_profile_image_of_a_boy_with_ca_1.jpg" // Placeholder image
                        alt="Profile"
                        fluid={true} // Ensuring the image is fluid
                        style={{ width: '100px', height: '100px', borderRadius: '50%' }} // Adjust the size
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p>First Name: {userData.first_name}</p>
                      <p>Unique ID: {userData.unique_id}</p> {/* Display unique ID */}
                      <p>Mobile Number: {userData.phone_number || 'N/A'}</p> {/* Handle null values */}
                      <p>Email: {userData.email}</p>
                      <p>Status: {userData.status}</p> {/* Display user status */}

                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default Userprofile;
