import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import axios from 'axios';
import './userPasswordUpdate.css';

function UserPasswordUpdate() {
    const [token, setToken] = useState(null);

    const location = useLocation();

    const getEmailFromSearchParams = () => {
        const params = new URLSearchParams(location.search);
        return params.get('email') || ''; 
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    const onSubmit = async (values) => {
        try {
            const payload = {
                email: values.email,
                password: values.password
            };

            const response = await axios.put(`http://${process.env.REACT_APP_IP_ADDRESS}:${process.env.REACT_APP_PORT}/api/user/update-user-password`, payload);

            if (response.status === 200) {
                const token = response.data.data.token;
                console.log(token, "<<<<<<<<<token");
                localStorage.setItem('token', token);
                setToken(token);
                window.location.href = '/home';
            } else {
                console.log(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(`Error: ${error.message}`);
            alert('Error updating the password');
        }
    };

    const formik = useFormik({
        initialValues: {
            email: getEmailFromSearchParams(),
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    });

    return (
        <div className="update-container update__img">
            <div>
                <Form onSubmit={formik.handleSubmit} className="update__form">
                    <h1 className="update__title">Create a new Password!</h1>
                    <div className="update__content">
                        <div className="update__box">
                            <i className="ri-mail-line update__icon"></i>
                            <div className="update__box-input">
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email" 
                                    {...formik.getFieldProps('email')} 
                                    value={formik.values.email}
                                    readOnly 
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-danger">{formik.errors.email}</div>
                                )}
                            </div>
                        </div>
                        <input 
                            type="hidden" 
                            name="email" 
                            value={formik.values.email} 
                        />
                        <div className="update__box">
                            <i className="ri-lock-2-line login__icon"></i>
                            <div className="update__box-input">
                                <Form.Control 
                                    type="password" 
                                    placeholder="New Password" 
                                    {...formik.getFieldProps('password')} 
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className="text-danger">{formik.errors.password}</div>
                                )}
                            </div>
                        </div>
                        {/* Confirm Password Field */}
                        <div className="update__box">
                            <i className="ri-lock-2-line login__icon"></i>
                            <div className="update__box-input">
                                <Form.Control 
                                    type="password" 
                                    placeholder="Confirm new Password" 
                                    {...formik.getFieldProps('confirmPassword')} 
                                />
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                    <div className="text-danger">{formik.errors.confirmPassword}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default UserPasswordUpdate;
