import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <div className="privacy-container">
            <h1>Privacy Policy</h1>
            <p>We value the trust you place in us. That’s why we adhere to the highest standards for secure transactions and customer information privacy. Please read the following statement to understand our practices regarding the collection, use, and disclosure of your information.</p>
            <h2>Note:</h2>
            <p>Our privacy policy is subject to change without notice. To stay updated, please review this policy periodically. By visiting our website or using our application, you agree to the terms of this Privacy Policy. If you do not agree, please do not use or access our website or application. By using our platform, you consent to our use and disclosure of your personal information as described in this Privacy Policy. This policy is subject to our Terms of Use.</p>
            
            <h2>Collection of Personal Information</h2>
            <p><strong>a.</strong> We collect and store personal information provided by you to deliver a safe, efficient, and customized experience. This includes your name, contact details, email, and bank account information, which helps us provide direct and team sales income in our MLM structure.</p>
            <p><strong>b.</strong> You may browse some sections of our website or application anonymously, but certain areas or services require you to provide information. You can choose not to provide information by opting out of specific services or features.</p>
            <p><strong>c.</strong> We use cookies and similar technologies to track behavior and preferences on our website and application, enhancing user experience and security. You can modify your cookie settings through your browser.</p>
            <p><strong>d.</strong> If you make purchases or transactions, we may collect payment and billing information. We also collect information through correspondence or feedback you share with us.</p>
            
            <h2>Use of Information</h2>
            <p><strong>a.</strong> We use your information to facilitate services, including processing transactions, resolving issues, promoting a safe service, measuring customer interest, and updating you about offers or features.</p>
            <p><strong>b.</strong> We analyze user demographics, interests, and behavior for better service offerings. Additionally, your IP address helps diagnose server issues and administer our platform.</p>
            
            <h2>Sharing of Personal Information</h2>
            <p><strong>a.</strong> We may share your information with trusted third parties as necessary to provide services (e.g., payments and deliveries) or for marketing and operational purposes.</p>
            <p><strong>b.</strong> Your information may be disclosed to comply with legal obligations, enforce agreements, prevent fraud, or ensure user and public safety.</p>
            <p><strong>c.</strong> We do not share your information with third parties for marketing purposes without explicit consent. Legal disclosures may occur as required by law or to respond to court orders.</p>
            <p><strong>d.</strong> In case of a merger, acquisition, or business restructuring, we may transfer your information to the new entity, which will follow this policy.</p>
            
            <h2>Links to Other Sites/Applications</h2>
            <p>Our website and application may link to third-party websites or applications that have separate privacy policies. We are not responsible for their content or practices.</p>
            
            <h2>Security Precautions</h2>
            <p>We implement stringent security measures to protect against data loss, misuse, and unauthorized access. Your information is safeguarded under strict guidelines.</p>
            
            <h2>Your Consent</h2>
            <p>By using our website or application, you consent to the collection, use, and sharing of your information as outlined in this policy. Updates to this policy will be posted here, and continued use of our platform indicates acceptance of these changes.</p>
            
            <h2>Grievance Officer</h2>
            <p>For grievances, please refer to our Grievance Redressal Mechanism available on our website, as per applicable regulations.</p>
        </div>
    );
}

export default PrivacyPolicy;
