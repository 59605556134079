import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap'; // Importing Bootstrap components
import './product.css'; // Import the CSS file for ProductDetails

function ProductDetails() {
  const [showDescription, setShowDescription] = useState(false);
  const navigate = useNavigate();

  const product = {
    id: 1,
    name: 'Key Soul Infinity Perfume',
    price: 1599,
    discount: 100,
    finalPrice: 1499,
    imageUrl: './rcm_vyomini_jute_bad.jpg' // Add an image URL property
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const handleBuyNow = () => {
    navigate('/Buying-Product', { state: { product } });
  };

  return (
    <>
     <Container className="mt-4 productDetailsContainer">
  <h1 className="productDetailsTitle">Product Details</h1>
  <Card key={product.id} className="my-3 productDetailsCard">
    <Card.Body className="d-flex flex-wrap">
      <div className="productDetailsImageContainer">
        <img src={product.imageUrl} className="productDetailsImage" alt="product" />
      </div>
      <div className="productDetailsContent">
        <h6 className="productDetailsName">{product.name}</h6>
        <h2 className="productDetailsPrice">
          ₹{product.finalPrice}{' '}
          <span className="productDetailsOriginalPrice">₹{product.price}</span>
        </h2>
        <Button className="productDetailsBuyButton" onClick={handleBuyNow}>
          Buy Now
        </Button>
        <br /><br />
        <h4 className="productDetailsHeading">Product Details</h4>
        <br />
        <div onClick={toggleDescription} className="productDetailsDescriptionToggle">
          DESCRIPTION<br /><br />
          <p className="descriptionText">
            Time to reveal the authentic charm! Now, you can unleash the real softness and beauty of your skin...
            <span className="productDetailsClickHere">Click here</span>
          </p>
        </div>

        {showDescription && (
          <div className="productDetailsDescriptionContent">
            <p>
              <strong>Time To Reveal Authentic Charm:</strong><br /><br />
              <strong>Key Features:</strong><br />
              Vyomini Moringa Cream:<br />
              . A perfect therapy for youthful, naturally radiant skin.<br />
              . Moringa extract helps optimize a visibly refined texture of skin.<br />
              . Moisturize, enhance and promote an impressively healthy skin.<br />
              . Lightweight, quickly absorb & non-sticky.<br />
              . Brilliant combination of organic resources (Moringa, Milk & Honey) full of nutrients.
            </p>
          </div>
        )}
      </div>
    </Card.Body>
  </Card>
</Container>


    </>
  );
}

export default ProductDetails;
