import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';
import axios from 'axios';
import './orderHistory.css';
import Header from './navbar/header';

const Orderhistory = () => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch order details
  const fetchOrderDetails = async () => {
    try {
      const token = localStorage.getItem('token'); // Get token from localStorage or your preferred storage
      const response = await axios.get(`http://${process.env.REACT_APP_IP_ADDRESS}:${process.env.REACT_APP_PORT}/api/order/user-order-detail`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add token to headers
        },
      });
      setOrderHistory(response.data.data); // Set order history data
    } catch (err) {
      setError('Failed to fetch order history.'); // Set error message
      console.error(err);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch order details when the component mounts
  useEffect(() => {
    fetchOrderDetails();
  }, []);

  if (loading) return <p>Loading...</p>; // Show loading state

  return (
    <>
      <Header />
      <Container className="mt-4 containerhistory">
        <h1>Order History</h1>
        {error && <p className="text-danger">{error}</p>} {/* Show error if any */}
        {orderHistory.length > 0 ? (
          orderHistory.map(order => (
            <Card key={order.id} className="my-3">
              <Card.Body className='cardhistory'>
                <Card.Title className='card-title-history'>Order ID: {order.id}</Card.Title>
                <Card.Img variant="top" src='rcm_vyomini_jute_bad.jpg' alt="kit" className='ordered-product-image' />
                <Card.Text className='card-text-history'>Status: {order.status}</Card.Text>
                <Card.Text className='card-text-history'>Product: kit</Card.Text>
                <Card.Text className='card-text-history'>Delivery Address: {order.userAddress.shipping_address}, {order.userAddress.city},{order.userAddress.state},{order.userAddress.country},{order.userAddress.postal_Code}</Card.Text>
                <Card.Text className='card-text-history'>Order Date: {new Date(order.createdAt).toLocaleString()}</Card.Text>
                <Card.Text className='card-text-history'>Expected Delivery : within 7 days</Card.Text>
                <Card.Text className='card-text-history'>Total Price: INR {order.total_amount}</Card.Text>
              </Card.Body>
            </Card>
          ))
        ) : (
          <p>No order history available</p>
        )}
      </Container>
    </>
  );
};

export default Orderhistory;
