// Dashboard.js

import React from 'react';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { Banner, Testimonials, Achievements } from './DashboardEnhancements';
import 'bootstrap/dist/css/bootstrap.min.css';
import './dashboard.css';
import './DashboardEnhancements.css';
import HeaderDashboard from './Headerdashboard';
import Footer from './Footer';

function Dashboard() {
  return (
    <>
   
    <HeaderDashboard/>
    <Container className="dashboard-container">
      {/* Company Logo Section */}
      <div className="company-logo-section text-center mb-4">
        <img src='./earnersWaveLogo.png' alt="Company Logo" className="company-logo" />
        <h1>Welcome to earnerswave.com </h1>
        <p>Your journey to premium quality starts here!</p>
      </div>

      {/* Banner Section */}
      <Banner />

      {/* Kit Section */}
      <div className="kit-details">
        <h2>Our Premium Kit</h2>
        <Row className="kit-row">
          {[...Array(4)].map((_, index) => (
            <Col md={3} key={index}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./rcm_vyomini_jute_bad.jpg" />
                <Card.Body>
                  <Card.Title>Product {index + 1}</Card.Title>
                  <Card.Text>
                    Essential and premium quality for daily use.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Button variant="primary" className="explore-kit-button">Explore the Kit</Button>
      </div>

      {/* MLM Overview Section */}
      <div className="mlm-overview text-center mb-4">
        <h2 className="mlm-title">Unlock Your Potential with Our MLM Program</h2>
        <p className="mlm-description">
          Join our network and start earning through referrals and sales. Here’s how it works:
        </p>
        <Row className="mlm-benefits-row">
          <Col md={4} className="mlm-benefit">
            <div className="mlm-benefit-icon">
              <i className="fas fa-users fa-3x"></i>
            </div>
            <h4>Build Your Team</h4>
            <p>Invite friends and family to join, and watch your team grow!</p>
          </Col>
          <Col md={4} className="mlm-benefit">
            <div className="mlm-benefit-icon">
              <i className="fas fa-coins fa-3x"></i>
            </div>
            <h4>Earn Commission</h4>
            <p>Receive commissions for every sale made by your referrals.</p>
          </Col>
          <Col md={4} className="mlm-benefit">
            <div className="mlm-benefit-icon">
              <i className="fas fa-trophy fa-3x"></i>
            </div>
            <h4>Achieve Rewards</h4>
            <p>Unlock exclusive bonuses and rewards as you hit milestones.</p>
          </Col>
        </Row>
        <Button variant="success" className="get-started-button">Get Started</Button>
      </div>

      {/* Income Structure Section */}
      <div className="income-structure text-center mb-4">
        <h2>Your Income Structure</h2>
        <p className="income-description">
          Explore how our structured income program can enhance your earnings!
        </p>
        <Row className="income-level-row">
          <Col md={6} className="income-level">
            <div className="income-level-card">
              <h4>Level 1: Direct Referrals</h4>
              <p>Earn a bonus for each direct referral.</p>
            </div>
          </Col>
          <Col md={6} className="income-level">
            <div className="income-level-card">
              <h4>Level 2: Team Earnings</h4>
              <p>Receive a percentage from team members' sales.</p>
            </div>
          </Col>
        </Row>
        <Button variant="info" className="learn-more-button">Learn More</Button>
      </div>

      {/* Testimonials Section */}
      <Testimonials />

      {/* Achievements Section */}
      <Achievements />
    </Container>
    <Footer/>
     </>
  );
 
}

export default Dashboard;
