// Footer.js

import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="custom-footer">
            <div className="footer-content">
                <div className="footer-section company-info">
                    <h4>About Us</h4>
                    <p>
                        Earners Wave is committed to providing the best opportunities
                        for individuals to achieve financial freedom through our
                        innovative solutions.
                    </p>
                </div>
                <div className="footer-section nav-links">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><a href="#products">Products</a></li>
                        <li><a href="#mlm-overview">MLM Overview</a></li>
                        <li><a href="#income-structure">Income Structure</a></li>
                        <li><a href="#testimonials">Testimonials</a></li>
                        <li><a href="#achievements">Achievements</a></li>
                    </ul>
                </div>
                <div className="footer-section policy-links">
                    <h4>Policies</h4>
                    <ul>
                        <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
                <div className="footer-section social-media">
                    <h4>Follow Us</h4>
                    <div className="social-icons">
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <FaFacebookF />
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <FaTwitter />
                        </a>
                        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                            <FaLinkedinIn />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© {new Date().getFullYear()} Earners Wave. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
