// DashboardEnhancements.js

import React from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import './DashboardEnhancements.css';

export const Banner = () => (
  <div className="enhancements-banner">
    <h1>Welcome to Our Exclusive MLM & E-Commerce Platform</h1>
    <p>Unlock rewards, make purchases, and grow your income exponentially!</p>
    <Button variant="primary" className="banner-button">Learn More</Button>
  </div>
);

export const Testimonials = () => (
  <Container className="testimonials-container">
    <h2>What Our Members Say</h2>
    <Row>
      <Col md={4}>
        <Card className="testimonial-card">
          <Card.Body>
            <Card.Text>"This platform changed my life! Earnings and opportunities are limitless."</Card.Text>
            <h5>- Alex D.</h5>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="testimonial-card">
          <Card.Body>
            <Card.Text>"Amazing products and a rewarding MLM program. Highly recommended!"</Card.Text>
            <h5>- Jamie S.</h5>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="testimonial-card">
          <Card.Body>
            <Card.Text>"Great platform to start your own business and grow together!"</Card.Text>
            <h5>- Priya K.</h5>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);

export const Achievements = () => (
  <Container className="achievements-container">
    <h2>Your Achievements</h2>
    <Row>
      <Col md={4} className="achievement-col">
        <i className="fas fa-trophy achievement-icon"></i>
        <h4>Top Seller</h4>
        <p>You’ve reached the highest sales level!</p>
      </Col>
      <Col md={4} className="achievement-col">
        <i className="fas fa-users achievement-icon"></i>
        <h4>Team Leader</h4>
        <p>Your team is growing fast!</p>
      </Col>
      <Col md={4} className="achievement-col">
        <i className="fas fa-coins achievement-icon"></i>
        <h4>Earnings Milestone</h4>
        <p>You’ve hit your first $10,000 in earnings!</p>
      </Col>
    </Row>
  </Container>
);
