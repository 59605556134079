import React, { useState } from 'react'; 
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap'; 
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MarketingPlans = () => {
  // State to manage language toggle
  const [isHindi, setIsHindi] = useState(false);

  // Function to toggle language
  const toggleLanguage = () => {
    setIsHindi(!isHindi);
  };

  // Data for line chart
  const data = {
    labels: ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'],
    datasets: [
      {
        label: 'Earnings Growth (in $)',
        data: [12700, 19050, 25400, 50000, 100000],
        fill: false,
        backgroundColor: '#007bff',
        borderColor: '#007bff',
        tension: 0.4,
      },
    ],
  };

  // Options for line chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Earnings Growth Over Levels',
      },
    },
  };

  return (
    <Container className="faq-container">
      <Row className="mt-5 text-center">
        <h1 className="faq-title">Earners Program Overview</h1>
        <p className="faq-description">
          Our Earners program offers you a unique opportunity to earn income through direct sales and by building a team. As your team grows, your earnings increase exponentially.
        </p>
      </Row>

      {/* Income Structure Table */}
      <Row className="mt-4">
        <Col md={12}>
          <Card className="faq-card shadow p-3">
            <Card.Body>
              <Card.Title className="faq-card-title">Income Structure</Card.Title>
              <Table striped bordered hover responsive className="faq-table">
                <thead>
                  <tr>
                    <th>Level</th>
                    <th>Direct Sales Earnings</th>
                    <th>Total Earnings</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Level 1</td>
                    <td>₹100</td>
                    <td>₹12700</td>
                  </tr>
                  <tr>
                    <td>Level 2</td>
                    <td>₹150</td>
                    <td>₹19050</td>
                  </tr>
                  <tr>
                    <td>Level 3</td>
                    <td>₹200</td>
                    <td>₹25400</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Description */}
      <Row className="mt-5">
        <Col md={12}>
          <Card className="faq-card shadow p-3">
            <Card.Body>
              <Card.Title className="faq-card-title">
                {isHindi ? 'Earners कैसे काम करता है' : 'How Our Earners Works'}
              </Card.Title>
              <p className="faq-text">
                {isHindi
                  ? 'Earners से आप दो तरीके से पैसे कमा सकते हैं:'
                  : 'Our Earners helps you earn money in two simple ways:'}
              </p>
              <ul className="faq-list">
                <li>
                  <b>{isHindi ? 'सीधी बिक्री:' : 'Direct Sales:'}</b>
                  {isHindi
                    ? 'हर प्रोडक्ट को बेचने पर आपको कमीशन मिलेगा।'
                    : ' You earn commission for each product you sell.'}
                </li><br/>
                <li>
                  <b>{isHindi ? 'टीम बिक्री:' : 'Team Sales:'}</b>
                  {isHindi
                    ? 'जब आपकी टीम बिक्री करती है, तो आपको बोनस और कमीशन मिलेगा।'
                    : ' You earn bonuses and commissions when your team makes sales.'}
                </li>
              </ul>
              <p className="faq-text">
                {isHindi
                  ? 'जैसे-जैसे आपकी टीम बढ़ती है, आपकी कमाई भी बढ़ती है, जिससे आपको ज्यादा पैसा कमाने का मौका मिलता है।'
                  : 'As your team grows, your earnings increase, giving you more opportunities to earn.'}
              </p>
              <Button variant="primary" onClick={toggleLanguage}>
                {isHindi ? 'English' : 'हिंदी'}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Earnings Growth Graph */}
      <Row className="mt-4">
        <Col md={12}>
          <Card className="shadow p-3">
            <Card.Body>
              <Card.Title>Earnings Growth Over Levels</Card.Title>
              <Line data={data} options={options} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MarketingPlans;