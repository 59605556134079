import React, { useState, useEffect } from 'react';
import { Accordion, Button, Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './userAddress.css';
import Header from '../navbar/header';

const UserAddressDetails = () => {
    const [addresses, setAddresses] = useState([]);
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
    const [activeKey, setActiveKey] = useState(['0']);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // Add state for error message
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchUserAddresses = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`http://${process.env.REACT_APP_IP_ADDRESS}:${process.env.REACT_APP_PORT}/api/user/get-user-address`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.data.status) {
                    setAddresses(response.data.data);
                } else {
                    console.error('Error fetching addresses:', response.data.message);
                }
            } catch (err) {
                console.error('Error fetching addresses:', err);
            }
        };

        fetchUserAddresses();
    }, []);

    const formik = useFormik({
        initialValues: {
            shipping_address: '',
            city: '',
            state: '',
            postal_Code: ''
        },
        validationSchema: Yup.object({
            shipping_address: Yup.string().required('Street is required'),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            postal_Code: Yup.string().required('Postal Code is required')
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.post(
                    `http://${process.env.REACT_APP_IP_ADDRESS}:${process.env.REACT_APP_PORT}/api/user/add-user-address`,
                    values,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                setAddresses([...addresses, values]);
                setShowAddressForm(false);
                setSelectedAddressIndex(addresses.length);
                resetForm();
            } catch (err) {
                console.error('Error adding address:', err);
            }
        }
    });

    const productDetails = {
        name: 'Product kit',
        mrp: 1770,
        gst: 270,
        discount: 271,
        totalPrice: 1499,
    };

    const toggleAccordion = (key) => {
        if (activeKey.includes(key)) {
            setActiveKey(activeKey.filter(k => k !== key));
        } else {
            setActiveKey([...activeKey, key]);
        }
    };

    const handleAddressSelection = (index) => {
        setSelectedAddressIndex(index);
        localStorage.setItem('selectedAddressId', addresses[index]?.id);
    };

    const handlePayNow = () => {
        setShowPaymentModal(true);
    };

    const handleConfirmPayment = async () => {
        try {
            const token = localStorage.getItem('token');

            const userProfile = JSON.parse(localStorage.getItem('userProfile'));
            const unique_id = userProfile?.unique_id;

            const response = await axios.post(`http://${process.env.REACT_APP_IP_ADDRESS}:${process.env.REACT_APP_PORT}/api/order/new-order`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.status) {
                console.log('Order placed successfully');

                const parent_id = localStorage.getItem('parent_id');
                const sponsor_id = localStorage.getItem('sponsor_id');

                await axios.post(`http://${process.env.REACT_APP_IP_ADDRESS}:${process.env.REACT_APP_PORT}/api/distribution/updatestatus`,
                    {
                        parent_id,
                        sponsor_id,
                        unique_id
                    }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                navigate('/home'); // Redirect to order history page
            } else if (response.data.message.includes("Your account is already active")) {
                // Set the specific error message
                setErrorMessage("Your account is already active. Please log in with a new Gmail or add a new account.");
            } else {
                console.error('Error placing order:', response.data.message);
            }

            setShowPaymentModal(false);
        } catch (error) {
            console.error('Error confirming payment:', error);
            setErrorMessage('Your account is already active. Please log in with a new Gmail or add a new account.');
        }
    };



    return (
        <>
        <Header/>
            <Accordion className="ecommerce-accordion" activeKey={activeKey}>
                <Accordion.Item eventKey="0" className="accordion-item">
                    <Accordion.Header className="accordion-header" onClick={() => toggleAccordion('0')}>
                        Your Address
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                        {addresses.length > 0 ? (
                            addresses.map((address, index) => (
                                <div key={index} className="address-display">
                                    <Form.Check
                                        type="radio"
                                        label={`${address.shipping_address}, ${address.city}, ${address.state} - ${address.postal_Code}`}
                                        name="addressOptions"
                                        checked={selectedAddressIndex === index}
                                        onChange={() => handleAddressSelection(index)}
                                        className="address-option"
                                    />
                                </div>
                            ))
                        ) : (
                            <div>No address added yet.</div>
                        )}
                        <Button variant="link" className="add-address-btn" onClick={() => setShowAddressForm(!showAddressForm)}>
                            {showAddressForm ? 'Cancel' : addresses.length > 0 ? 'Add New Address' : 'Add Address'}
                        </Button>
                        {showAddressForm && (
                            <Form onSubmit={formik.handleSubmit} className="address-form">
                                <Form.Group controlId="shipping_address" className="form-group">
                                    <Form.Label className="form-label">Street</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your street"
                                        name="shipping_address"
                                        value={formik.values.shipping_address}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="address-input"
                                        isInvalid={formik.touched.shipping_address && !!formik.errors.shipping_address}
                                        required
                                    />
                                    {formik.touched.shipping_address && formik.errors.shipping_address ? (
                                        <div className="invalid-feedback">{formik.errors.shipping_address}</div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group controlId="city" className="form-group">
                                    <Form.Label className="form-label">City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your city"
                                        name="city"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="address-input"
                                        isInvalid={formik.touched.city && !!formik.errors.city}
                                        required
                                    />
                                    {formik.touched.city && formik.errors.city ? (
                                        <div className="invalid-feedback">{formik.errors.city}</div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group controlId="state" className="form-group">
                                    <Form.Label className="form-label">State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your state"
                                        name="state"
                                        value={formik.values.state}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="address-input"
                                        isInvalid={formik.touched.state && !!formik.errors.state}
                                        required
                                    />
                                    {formik.touched.state && formik.errors.state ? (
                                        <div className="invalid-feedback">{formik.errors.state}</div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group controlId="postal_Code" className="form-group">
                                    <Form.Label className="form-label">Postal Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your postal code"
                                        name="postal_Code"
                                        value={formik.values.postal_Code}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="address-input"
                                        isInvalid={formik.touched.postal_Code && !!formik.errors.postal_Code}
                                        required
                                    />
                                    {formik.touched.postal_Code && formik.errors.postal_Code ? (
                                        <div className="invalid-feedback">{formik.errors.postal_Code}</div>
                                    ) : null}
                                </Form.Group>
                                <Button variant="primary" type="submit" className="submit-btn">
                                    Submit
                                </Button>
                            </Form>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="accordion-item">
                    <Accordion.Header className="accordion-header" onClick={() => toggleAccordion('1')}>
                        Product Details
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                        <h5 className="product-name">{productDetails.name}</h5>
                        <p className="product-mrp">MRP: ₹{productDetails.mrp}</p>
                        <p className="product-gst">GST: ₹{productDetails.gst}</p>
                        <p className="product-discount">Discount: -₹{productDetails.discount}</p>
                        <h5 className="total-price">Total Price: ₹{productDetails.totalPrice}</h5>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {/* Modal for payment confirmation */}
            <Button
                variant="primary"
                className={`pay-now-btn ${addresses.length === 0 || selectedAddressIndex === null ? 'disabled-btn-pay-now' : ''}`}
                onClick={handlePayNow}
                disabled={addresses.length === 0 || selectedAddressIndex === null}
            >
                Pay Now
            </Button>


            {/* Modal for payment confirmation */}
            <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Total amount to be paid: ₹{productDetails.totalPrice}</p>
                    <p>Selected Address: {addresses[selectedAddressIndex]?.shipping_address}, {addresses[selectedAddressIndex]?.city}</p>
                    {errorMessage && <p className="error-message-alrady-activated">{errorMessage}</p>} {/* Display error message */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPaymentModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmPayment}>
                        Confirm Payment
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UserAddressDetails;
