import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Orderhistory from "../components/orderHistory";
import ProductDetails from "../components/product/product";
import TransactionHistory from "../components/wallet/withdrwalHistory";
import MarketingPlans from "../components/marketingPlans/marketingPlans";
import UserAddressDetails from "../components/userAddress/userAddress";
import MembersData from "../components/members/membersTree";
import HelpDeskPage from "../components/helpdesk/helpdesk";
import Userlogin from "../components/login/login";
import UserSignup from "../components/signup/signup";
import UserPasswordUpdate from "../components/userUpdate/userPasswordUpdate";
import HomePage from "../components/home/home";
import Dashboard from "../components/Dashboard/dashboard";
import TermsAndConditions from "../components/Terms&Conditions/TermsAndConditions";
import PrivacyPolicy from "../components/privacyPolicy/PrivacyPolicy";

function Approute() {
    return (<Router>
        <Routes>
            <Route exact path="/" element={<Dashboard/>} />
            <Route exact path="/home" element={<HomePage/>} />
            <Route exact path="/signup" element={<UserSignup />} />
            <Route exact path="/login" element={<Userlogin />} />
            <Route exact path="/user-Password-update" element={<UserPasswordUpdate />} />
            <Route exact path="/members-data" element={<MembersData/>} />
            <Route exact path="/order-history" element={<Orderhistory/>} />
            <Route exact path="/product" element={<ProductDetails/>} />
            <Route exact path="/wallet" element={<TransactionHistory/>} />
            <Route exact path="/Plans" element={<MarketingPlans/>} />
            <Route exact path="/Buying-Product" element={<UserAddressDetails/>} />
            <Route exact path="/help" element={<HelpDeskPage/>} />
            <Route exact path="/terms-and-conditions" element={<TermsAndConditions/>} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} />





        </Routes>
    </Router>
    )
}

export default Approute;