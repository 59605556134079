import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './signup.css';

function UserSignup() {
    const [token, setToken] = useState(null);
    const [searchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const [termsAccepted, setTermsAccepted] = useState(false); // State for terms acceptance

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);

    useEffect(() => {
        const sponsor_id = searchParams.get('sponsor_id') || 'master4023';
        const parent_id = searchParams.get('parent_id') || 'master4023';

        localStorage.setItem('sponsor_id', sponsor_id);
        localStorage.setItem('parent_id', parent_id);
    }, [searchParams]);

    const validationSchema = Yup.object({
        firstName: Yup.string()
            .matches(/^[a-zA-Z]+$/, 'First Name must contain only alphabetic characters')
            .required('First Name is required'),
        phoneNumber: Yup.string()
            .matches(/[6-9][0-9]{9}/, 'Invalid Phone Number')
            .required('Phone Number is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required'),
    });

    const onSubmit = async (values) => {
        try {
            const myUrl = `http://${process.env.REACT_APP_IP_ADDRESS}:${process.env.REACT_APP_PORT}/api/user/create`;
            const response = await axios.post(myUrl, {
                "first_name": values.firstName,
                "phone_number": values.phoneNumber,
                "email": values.email,
                "password": values.password,
            });

            if (response.status === 200) {
                const token = response.data.data.token;
                localStorage.setItem('token', token);
                setToken(token);
                window.location.href = '/home';
            } else {
                console.log(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(`Error: ${error.message}`);
            alert('Please check your email or password');
        }
    };

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            phoneNumber: '',
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    });

    return (
        <div className="signup-container">
            <div className="glass-card">
                <h1 className="signup-title">Sign Up Here! EARNER</h1>
                <Form onSubmit={formik.handleSubmit} className="signup-form">
                    <div className="input-group">
                        <input
                            type="text"
                            placeholder="First Name"
                            {...formik.getFieldProps('firstName')}
                            className="signup-input"
                        />
                        {formik.touched.firstName && formik.errors.firstName && (
                            <div className="error-text">{formik.errors.firstName}</div>
                        )}
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            placeholder="Phone Number"
                            {...formik.getFieldProps('phoneNumber')}
                            className="signup-input"
                        />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                            <div className="error-text">{formik.errors.phoneNumber}</div>
                        )}
                    </div>
                    <div className="input-group">
                        <input
                            type="email"
                            placeholder="Email"
                            {...formik.getFieldProps('email')}
                            className="signup-input"
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className="error-text">{formik.errors.email}</div>
                        )}
                    </div>
                    <div className="input-group">
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            {...formik.getFieldProps('password')}
                            className="signup-input"
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className="error-text">{formik.errors.password}</div>
                        )}
                        <div className="toggle-password">
                            <input
                                type="checkbox"
                                id="signup-check"
                                onClick={togglePasswordVisibility}
                            />
                            <label htmlFor="signup-check">
                                {showPassword ? "Hide" : "Show"} password
                            </label>
                        </div>
                    </div>
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            id="terms"
                            name="terms"
                            required
                            checked={termsAccepted}
                            onChange={() => setTermsAccepted(!termsAccepted)}
                        />
                        <label htmlFor="terms">
                            <a href="/terms-and-conditions" >
                                Terms & Conditions
                            </a> and <a href="/privacy-policy" >
                                Privacy Policy
                            </a>
                        </label>
                    </div>
                    <Button
                        variant="primary"
                        type="submit"
                        className="signup-button"
                        disabled={!termsAccepted} 
                    >
                        Signup
                    </Button>
                    <p className="signup-login">
                        Already have an account? <a href="login/">Login</a>
                    </p>
                </Form>
            </div>
        </div>
    );
}

export default UserSignup;
