import React from 'react';
import './header.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Userprofile from '../userProfile';
import WithdrwalReq from '../withdrawalRequest';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';

function Header() {
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleWhatsAppClick = () => {
        navigate('/help'); // Navigate to the help route
    };

    return (
        <>
            <Navbar expand={false} className="bg-body-tertiary mb-3">
                <Container fluid>
                    <Navbar.Brand href="/home">
                        <img
                            src='./earnersWaveLogo.png'
                            width={"100rem"}
                            height={"50rem"}
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    <div className="menu-list">
                        <div className='menu-item' onClick={handleWhatsAppClick}>
                            <FaWhatsapp size={50} className='whatsapp-icon-header menu-icon' />
                        </div>
                    </div>
                    <div className='withdrawalsymobl'>
                        <WithdrwalReq />
                    </div>
                    <div>
                        <Userprofile />
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
