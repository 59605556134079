import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../navbar/header';
import ReferenceLinkModal from './ReferenceLinkModal';
import ReferenceLinkCard from './ReferenceLinkCard';
import InfoCard from '../cards/HomePageInfoCard';
import ProductDetails from '../product/product';
import BuyProduct from './BuyProductModel';
import MarketingPlans from '../marketingPlans/marketingPlans';
import axios from 'axios';

function HomePage() {
  const [showReferenceLinkModal, setShowReferenceLinkModal] = useState(false);
  const [showBuyProductModal, setShowBuyProductModal] = useState(false);
  const [userStatus, setUserStatus] = useState(null); // State to hold user status
  const [referenceLink, setReferenceLink] = useState(''); // State to hold the referral link

  const handleCloseReferenceLinkModal = () => setShowReferenceLinkModal(false);
  const handleCloseBuyProductModal = () => setShowBuyProductModal(false);

  // Function to fetch user profile details
const fetchUserProfile = async () => {
  const token = localStorage.getItem('token'); // Get token from local storage
  if (!token) {
    console.error('No token found. User might not be logged in.');
    return;
  }

  try {
    const response = await axios.get(`http://${process.env.REACT_APP_IP_ADDRESS}:${process.env.REACT_APP_PORT}/api/user/user-profile-details`, {
      headers: {
        Authorization: `Bearer ${token}`, // Send token in authorization header
      },
    });

    // Set user status from the API response
    const userProfile = response.data.data;
    setUserStatus(userProfile.status); // Assuming response.data has the status field
    console.log(userProfile.status, ">>> status");

    // Store user profile attributes in local storage
    localStorage.setItem('userProfile', JSON.stringify({
      first_name: userProfile.first_name,
      unique_id: userProfile.unique_id,
      phone_number: userProfile.phone_number,
      email: userProfile.email,
      status: userProfile.status,
      level: userProfile.level,
      childs: userProfile.childs,
      Balance: userProfile.Balance
    }));

  } catch (error) {
    console.error('Error fetching user profile:', error);
  }
};


  // Function to fetch the reference link
  const fetchReferenceLink = async () => {
    const token = localStorage.getItem('token'); // Get token from local storage
    if (!token) {
      console.error('No token found. User might not be logged in.');
      return;
    }

    try {
      const response = await axios.get(`http://${process.env.REACT_APP_IP_ADDRESS}:${process.env.REACT_APP_PORT}/api/user/referral-link`, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token in authorization header
        },
      });
      // Set the reference link from the API response
      setReferenceLink(response.data.data); // Assuming response.data has the link field
      console.log(response.data.data, ">>> reference link");
    } catch (error) {
      console.error('Error fetching referral link:', error);
    }
  };

  // Automatically fetch user profile details on component mount
  useEffect(() => {
    fetchUserProfile(); // Fetch user profile details on component mount
  }, []); // Run only on initial render

  // Fetch the referral link only if the user status is active
  useEffect(() => {
    if (userStatus === 'active') {
      fetchReferenceLink(); // Fetch referral link based on user status
      setShowReferenceLinkModal(true); // Show modal if user status is active
    } else if (userStatus === 'pending') {
      setShowBuyProductModal(true); // Show buy product modal if user status is pending
    }
  }, [userStatus]); // Depend on userStatus to fetch the link or show the modal

  return (
    <div>
      <Header />
      <div className="container mt-5">
        <InfoCard />
      </div>
      {userStatus === 'active' && (
        <>
          <ReferenceLinkModal
            showReferenceLink={showReferenceLinkModal}
            handleCloseReferenceLink={handleCloseReferenceLinkModal}
            referenceLink={referenceLink} // Pass the fetched reference link here
          />
          <ReferenceLinkCard referenceLink={referenceLink} /> {/* Pass the fetched reference link here */}
        </>
      )}

      {userStatus === 'pending' && (
        <BuyProduct
          showBuyProduct={showBuyProductModal}
          handleCloseBuyProduct={handleCloseBuyProductModal}
        />
      )}

      <ProductDetails />
      <MarketingPlans />
    </div>
  );
}

export default HomePage;
